import React, { useEffect } from 'react';

import { useSiteState } from '../lib/siteContext';

// Components
import Layout from '../components/layout';

const NotFoundPage = () => {

  const { setMyPorter, setHeaderDark, setFooterTheme } = useSiteState();  

  useEffect(() => {  
    document.documentElement.setAttribute('data-theme', `theme-grey-60`);
    document.documentElement.style.setProperty(`--doc-bg`, 'var(--color-grey-60)');          
    setFooterTheme('theme-none')          
    setMyPorter(false)
    setHeaderDark(true)
  }, [setMyPorter, setHeaderDark]);

  return (
    <Layout>    
      <div className="w-full min-h-page flex flex-col justify-center items-start px-gutter">
        <h1 className="text-xl">404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist yet.</p>
      </div>      
    </Layout>
  )
}

export default NotFoundPage
